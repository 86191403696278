
















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { ORodoList: () => import('@/components/organisms/globalTools/terms/rodo/o-rodo-list.vue')},

  setup(){

    return {}
  }
})
